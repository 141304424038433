import {
  HStack,
  Stack,
  Drawer,
  Button,
  DrawerContent,
  DrawerOverlay,
  Text,
  StackDivider,
  Heading,
  Box,
} from '@chakra-ui/react';
import { useFlag } from '@unleash/proxy-client-react';
import { ContentBox } from '../../components/ContentBox';
import { ProviderWithImplementation } from '../types';
import { ProviderIcon } from '../../components/Iconagraphy/ProviderIcon';
import { COLORS } from '../../constant';
import { Switch } from '../../components/Switch';
import { AuthorizationType } from '../components/AuthorizationType';
import { PinProvider } from '../components/PinProvider';
import { TrinetWarning } from '../components/TrinetWarning';
import { ConfigurationNeededWarning } from '../components/ConfigurationNeededWarning';
import { ConfigurationNeededToolTip } from '../components/ConfigurationNeededToolTip';
import {
  getConfigurationNeededText,
  getImplementations,
  isProviderEnabled,
} from '../utils';
import { useProviderSettings } from '../hooks/useProviderSettings';
import { useApplication } from 'applications/hooks';
import { FeatureFlag } from '../../constant/feature-flags';

export function EditProviderDrawer({
  shouldAllowUpdatesToProvider,
  selectedProvider,
  clearSelectedProvider,
  updateEnabled,
  updateIsPinned,
}: {
  shouldAllowUpdatesToProvider: (providerId: string) => boolean;
  selectedProvider: ProviderWithImplementation | null;
  clearSelectedProvider: () => void;
  updateEnabled: (providerId: string, enabled: boolean) => void;
  updateIsPinned: (providerId: string, pinnedIndex: boolean) => void;
}) {
  const { application } = useApplication();
  const isTrinet = selectedProvider?.id === 'trinet';
  const isGusto = selectedProvider?.id === 'gusto';
  const { providerSettings } = useProviderSettings();
  const isPayStatementMappingEnabled = useFlag(
    FeatureFlag.PayStatementMappingGusto,
  );

  const implementations = selectedProvider
    ? getImplementations(selectedProvider)
    : [];

  const providerEnabled = selectedProvider
    ? isProviderEnabled(
        selectedProvider,
        shouldAllowUpdatesToProvider(selectedProvider.id),
      )
    : false;

  const disableToggle = selectedProvider
    ? !shouldAllowUpdatesToProvider(selectedProvider.id)
    : false;

  const showAuthenticationTypeConfig = implementations.length > 1;

  const configurationNeededText =
    providerSettings &&
    selectedProvider &&
    getConfigurationNeededText({
      providerSettings,
      providerWithImplementation: selectedProvider,
      isSandbox: application?.isSandbox() ?? false,
    });

  return (
    <Drawer
      isOpen={!!selectedProvider}
      placement="right"
      onClose={clearSelectedProvider}
      size="xl"
    >
      <DrawerOverlay />
      <DrawerContent bg={COLORS.GRAY.GRAY_100}>
        {selectedProvider && (
          <HStack
            p="16px 24px"
            bg="white"
            justify="space-between"
            borderBottom={`1px solid ${COLORS.GRAY.GRAY_400}`}
          >
            <HStack>
              <ProviderIcon
                size="md"
                src={selectedProvider.icon}
                provider={selectedProvider.displayName}
              />
              <Heading as="h2" fontSize="18px">
                {selectedProvider.displayName}
              </Heading>
              <PinProvider
                provider={selectedProvider}
                updateIsPinned={updateIsPinned}
                enabled={providerEnabled}
                disableTooltip
              />
            </HStack>
            <Button
              variant="primaryPurple"
              size="md"
              onClick={clearSelectedProvider}
            >
              Done
            </Button>
          </HStack>
        )}

        {selectedProvider && (
          <Box overflowY="auto">
            {isTrinet && (
              <Box m="24px">
                <TrinetWarning />
              </Box>
            )}
            {configurationNeededText && (
              <Box m="24px">
                <ConfigurationNeededWarning
                  text={configurationNeededText.banner}
                />
              </Box>
            )}
            <ContentBox m="24px">
              <Stack divider={<StackDivider />}>
                <Stack p="24px">
                  <Text fontSize="sm" fontWeight="500">
                    Integration Status
                  </Text>
                  <HStack justify="space-between">
                    <Text
                      color={COLORS.GRAY.GRAY_600}
                      fontSize="14px"
                      flexBasis="45%"
                    >
                      When turned on, {selectedProvider.displayName} will be
                      shown to your customers in Finch Connect.
                    </Text>
                    <HStack justify="flex-end" spacing="16px">
                      {configurationNeededText && (
                        <ConfigurationNeededToolTip
                          text={configurationNeededText.tooltip}
                        />
                      )}
                      <Switch
                        id={selectedProvider.id}
                        enabled={providerEnabled}
                        onChange={() =>
                          updateEnabled(
                            selectedProvider.id,
                            !selectedProvider.enabled,
                          )
                        }
                        isDisabled={disableToggle}
                      />
                    </HStack>
                  </HStack>
                </Stack>

                {isGusto && isPayStatementMappingEnabled ? null : (
                  <HStack p="24px" justify="space-between" align="flex-start">
                    <Stack flexBasis="45%">
                      <Text fontSize="sm" fontWeight="500">
                        {implementations.length > 1
                          ? 'Authentication Types'
                          : 'Authentication Type'}
                      </Text>
                      {implementations.length > 1 && (
                        <Text color={COLORS.GRAY.GRAY_600} fontSize="sm">
                          Select which authentication types show in Finch
                          Connect. Setting a primary authentication type will
                          show it first.
                        </Text>
                      )}
                    </Stack>
                    <Stack spacing="24px" flexBasis="45%">
                      {implementations.map((implementation) => (
                        <AuthorizationType
                          key={implementation.id}
                          implementation={implementation}
                          defaultImplementationId={
                            selectedProvider.defaultImplementationId
                          }
                          requireOAuthCredentials={
                            selectedProvider.requireOAuthCredentialsInput
                          }
                          oauthClientId={
                            selectedProvider.oauthCredentials?.clientId || null
                          }
                          showAuthenticationTypeConfig={
                            showAuthenticationTypeConfig
                          }
                        />
                      ))}
                    </Stack>
                  </HStack>
                )}
              </Stack>
            </ContentBox>
          </Box>
        )}
      </DrawerContent>
    </Drawer>
  );
}
