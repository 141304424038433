import { COLORS } from '../../constant';
import {
  Flex,
  ModalBody,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
  Alert,
  AlertIcon,
  PinInput,
  PinInputField,
  Box,
} from '@chakra-ui/react';

import { Lock } from './Lock';
export const UnlockPublicPaystatementMappingModal = ({
  isOpen,
  onLock,
  correctPin,
  onChange,
  clearPin,
  setPin,
  pin,
}: {
  isOpen: boolean;
  onLock: (e: string) => void;
  correctPin: boolean | null;
  onChange: (e: string) => void;
  setPin: (e: string) => void;
  pin: string;
  clearPin: boolean;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered size="md">
      <ModalOverlay />
      <ModalContent maxWidth="400px" minHeight="700px" borderRadius="20px">
        <ModalBody>
          <Flex
            direction="column"
            gap={4}
            paddingTop={10}
            paddingBottom={5}
            minHeight={'500px'}
            justifyContent={'space-between'}
          >
            <Flex direction={'column'} gap={2} alignItems={'center'}>
              {correctPin === false && !clearPin ? (
                <Alert
                  status="error"
                  width={'100%'}
                  borderRadius={'10px'}
                  border={'1px solid #FDA29B'}
                  backgroundColor={'#FEF3F2'}
                  marginBottom={10}
                  padding={'10px'}
                >
                  <Flex direction={'row'} alignItems={'flex-start'}>
                    <AlertIcon
                      position={'relative'}
                      width={'16px'}
                      top={1}
                      color={'#B42318'}
                      height={'16px'}
                    />
                    <Text
                      style={{
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: '#B42318',
                      }}
                    >
                      The code entered is incorrect. Please double-check the
                      code and try again.
                    </Text>
                  </Flex>
                </Alert>
              ) : (
                <Box height={'98px'} />
              )}
              <Lock />
              <Text fontSize={'20px'} fontWeight={'600'}>
                This form is locked
              </Text>
              <Text textAlign={'center'}>
                Please enter the 4-digit code emailed to you to unlock this form
              </Text>
              <Flex
                paddingY={4}
                width={'100%'}
                justifyContent={'center'}
                gap={4}
              >
                <PinInput
                  size="md"
                  value={pin}
                  onChange={(e) => {
                    onChange(e);
                    setPin(e);
                  }}
                  autoFocus
                  onComplete={async (e) => {
                    await setTimeout(() => {
                      onLock(e);
                    }, 200);
                  }}
                >
                  <PinInputField
                    _focus={{
                      borderColor: COLORS.FINCH.PURPLE,
                      boxShadow: 'none',
                    }}
                    borderColor={COLORS.GRAY.GRAY_400}
                    _placeholder={{
                      display: 'none',
                      color: 'transparent',
                    }}
                  />
                  <PinInputField
                    _focus={{
                      borderColor: COLORS.FINCH.PURPLE,
                      boxShadow: 'none',
                    }}
                    borderColor={COLORS.GRAY.GRAY_400}
                    _placeholder={{
                      display: 'none',
                      color: 'transparent',
                    }}
                  />
                  <PinInputField
                    _focus={{
                      borderColor: COLORS.FINCH.PURPLE,
                      boxShadow: 'none',
                    }}
                    borderColor={COLORS.GRAY.GRAY_400}
                    _placeholder={{
                      display: 'none',
                      color: 'transparent',
                    }}
                  />
                  <PinInputField
                    _focus={{
                      borderColor: COLORS.FINCH.PURPLE,
                      boxShadow: 'none',
                    }}
                    borderColor={COLORS.GRAY.GRAY_400}
                    _placeholder={{
                      display: 'none',
                      color: 'transparent',
                    }}
                  />
                </PinInput>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
