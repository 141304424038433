export enum ConnectionDetailTab {
  OVERVIEW = 0,
  JOB_HISTORY = 1,
  PAY_STATEMENT_MAPPING = 2,
  AUTHENTICATION_PROGRESS = 3,
  SESSION_HISTORY = 4,
}

export const connectionDetailTabs: Record<ConnectionDetailTab, string> = {
  [ConnectionDetailTab.OVERVIEW]: 'Overview',
  [ConnectionDetailTab.JOB_HISTORY]: 'Job History',
  [ConnectionDetailTab.PAY_STATEMENT_MAPPING]: 'Payroll Mapping',
  [ConnectionDetailTab.AUTHENTICATION_PROGRESS]: 'Authentication Progress',
  [ConnectionDetailTab.SESSION_HISTORY]: 'Session History',
};
