import { ClipboardButton } from '../../components/ClipboardButton';
import { COLORS } from '../../constant';
import { Flex, Input, useToast } from '@chakra-ui/react';

export const CopyComponent = ({
  value,
  disabled,
}: {
  value: string;
  disabled: boolean;
}) => {
  const toast = useToast({
    duration: 3000,
    isClosable: true,
  });

  return (
    <Flex position={'relative'} style={{ width: '100%' }}>
      <Input
        value={value.length > 0 ? value : 'Copyable Link'}
        style={{
          fontSize: '14px',
          color: COLORS.FINCH.GRAY,
          paddingLeft: '10px',
          paddingRight: '30px',
          margin: '0px',
        }}
        defaultValue={'Copyable Link'}
        disabled={disabled}
      />
      <Flex
        position={'absolute'}
        right={'0px'}
        borderRadius={'4px'}
        pointerEvents={disabled ? 'none' : 'auto'}
        cursor={'pointer'}
        onClick={
          !disabled
            ? () => {
                navigator.clipboard.writeText(value);
                toast({
                  title: 'Copied to clipboard',
                  status: 'success',
                });
              }
            : undefined
        }
      >
        <ClipboardButton value={value} />
      </Flex>
    </Flex>
  );
};
