import { ReactNode } from 'react';
import { ContentBox } from '../../../../components/ContentBox';
import { Flex, Stack, Text } from '@chakra-ui/react';
import { Caption } from '../../../../shared/Typography';
import { Category, Item, Label } from '../types';
import flatten from 'lodash/flatten';
import { FilterType } from '../pages';

const CATEGORY_ICON_MAP: (isSelected: boolean) => Record<string, ReactNode> = (
  isSelected: boolean,
) => ({
  earnings: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9.99982"
        cy="10.0004"
        r="9"
        fill={isSelected ? '#3E4AE7' : '#737373'}
      />
      <path
        d="M9.29167 15.5H10.6875V14.5208C11.3681 14.4097 11.9306 14.1181 12.375 13.6458C12.8194 13.1736 13.0417 12.625 13.0417 12C13.0417 11.375 12.8507 10.8403 12.4688 10.3958C12.0868 9.95139 11.5 9.58333 10.7083 9.29167C9.86111 8.98611 9.30208 8.72569 9.03125 8.51042C8.76042 8.29514 8.625 8.01389 8.625 7.66667C8.625 7.38889 8.74653 7.15972 8.98958 6.97917C9.23264 6.79861 9.54861 6.70833 9.9375 6.70833C10.3264 6.70833 10.6667 6.80208 10.9583 6.98958C11.25 7.17708 11.4444 7.43056 11.5417 7.75L12.7708 7.22917C12.6042 6.77083 12.3368 6.38542 11.9688 6.07292C11.6007 5.76042 11.1736 5.5625 10.6875 5.47917V4.5H9.3125V5.5C8.6875 5.63889 8.1875 5.90625 7.8125 6.30208C7.4375 6.69792 7.25 7.15972 7.25 7.6875C7.25 8.3125 7.46181 8.84375 7.88542 9.28125C8.30903 9.71875 8.97917 10.0972 9.89583 10.4167C10.5208 10.6389 10.9757 10.875 11.2604 11.125C11.5451 11.375 11.6875 11.6667 11.6875 12C11.6875 12.3611 11.5417 12.6632 11.25 12.9062C10.9583 13.1493 10.5972 13.2708 10.1667 13.2708C9.70833 13.2708 9.30208 13.1181 8.94792 12.8125C8.59375 12.5069 8.35417 12.1111 8.22917 11.625L6.9375 12.1667C7.10417 12.75 7.39583 13.2465 7.8125 13.6562C8.22917 14.066 8.72222 14.3472 9.29167 14.5V15.5Z"
        fill="white"
      />
    </svg>
  ),
  employee_deductions: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11881_1664)">
        <path
          d="M9.29167 15.5H10.6875V14.5208C11.3681 14.4097 11.9306 14.1181 12.375 13.6458C12.8194 13.1736 13.0417 12.625 13.0417 12C13.0417 11.375 12.8507 10.8403 12.4688 10.3958C12.0868 9.95139 11.5 9.58333 10.7083 9.29167C9.86111 8.98611 9.30208 8.72569 9.03125 8.51042C8.76042 8.29514 8.625 8.01389 8.625 7.66667C8.625 7.38889 8.74653 7.15972 8.98958 6.97917C9.23264 6.79861 9.54861 6.70833 9.9375 6.70833C10.3264 6.70833 10.6667 6.80208 10.9583 6.98958C11.25 7.17708 11.4444 7.43056 11.5417 7.75L12.7708 7.22917C12.6042 6.77083 12.3368 6.38542 11.9688 6.07292C11.6007 5.76042 11.1736 5.5625 10.6875 5.47917V4.5H9.3125V5.5C8.6875 5.63889 8.1875 5.90625 7.8125 6.30208C7.4375 6.69792 7.25 7.15972 7.25 7.6875C7.25 8.3125 7.46181 8.84375 7.88542 9.28125C8.30903 9.71875 8.97917 10.0972 9.89583 10.4167C10.5208 10.6389 10.9757 10.875 11.2604 11.125C11.5451 11.375 11.6875 11.6667 11.6875 12C11.6875 12.3611 11.5417 12.6632 11.25 12.9062C10.9583 13.1493 10.5972 13.2708 10.1667 13.2708C9.70833 13.2708 9.30208 13.1181 8.94792 12.8125C8.59375 12.5069 8.35417 12.1111 8.22917 11.625L6.9375 12.1667C7.10417 12.75 7.39583 13.2465 7.8125 13.6562C8.22917 14.066 8.72222 14.3472 9.29167 14.5V15.5Z"
          fill={isSelected ? '#3E4AE7' : '#737373'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.959 9.11816L17.4662 9.2657C17.3956 8.5507 17.2198 7.83232 16.929 7.13022C16.6382 6.42811 16.2545 5.79587 15.7989 5.24033L16.9587 4.28914C17.5067 4.95737 17.967 5.71647 18.3148 6.55619C18.6627 7.39592 18.874 8.25813 18.959 9.11816ZM15.7106 3.04194L14.76 4.20227C13.6139 3.26332 12.2082 2.68106 10.7338 2.53456L10.8822 1.04191C12.6509 1.21765 14.3357 1.91553 15.7106 3.04194ZM9.11773 1.04129L9.26527 2.53402C8.55027 2.60469 7.83189 2.78043 7.12979 3.07125C6.42768 3.36207 5.79544 3.74578 5.2399 4.20138L4.2887 3.04154C4.95694 2.49351 5.71603 2.03325 6.55576 1.68543C7.39549 1.3376 8.2577 1.1263 9.11773 1.04129ZM3.04151 4.28961L4.20184 5.24021C3.26289 6.38634 2.68063 7.79204 2.53413 9.26641L1.04148 9.1181C1.21722 7.34938 1.9151 5.66456 3.04151 4.28961ZM1.04086 10.8825C1.12586 11.7426 1.33717 12.6048 1.685 13.4445C2.03282 14.2842 2.49308 15.0433 3.04111 15.7115L4.20095 14.7604C3.74534 14.2048 3.36164 13.5726 3.07081 12.8705C2.77999 12.1684 2.60426 11.45 2.53359 10.735L1.04086 10.8825ZM4.28918 16.9587L5.23977 15.7984C6.3859 16.7374 7.7916 17.3196 9.26598 17.4661L9.11767 18.9588C7.34895 18.783 5.66412 18.0852 4.28918 16.9587ZM10.8821 18.9594L10.7346 17.4667C11.4495 17.396 12.1679 17.2203 12.87 16.9294C13.5721 16.6386 14.2044 16.2549 14.7599 15.7993L15.7111 16.9591C15.0429 17.5072 14.2838 17.9674 13.4441 18.3153C12.6043 18.6631 11.7421 18.8744 10.8821 18.9594ZM16.9583 15.7111L15.798 14.7605C16.7369 13.6143 17.3192 12.2086 17.4657 10.7343L18.9583 10.8826C18.7826 12.6513 18.0847 14.3361 16.9583 15.7111Z"
          fill={isSelected ? '#3E4AE7' : '#737373'}
        />
      </g>
      <defs>
        <clipPath id="clip0_11881_1664">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  employer_contributions: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25 14.75H10.75V11.75H13.75V10.25H10.75V7.25H9.25V10.25H6.25V11.75H9.25V14.75ZM2.5 18V4.5H17.5V18H2.5ZM3.5 3.5V2H16.5V3.5H3.5Z"
        fill={isSelected ? '#3E4AE7' : '#737373'}
      />
    </svg>
  ),
  // taxes: (
  //   <svg
  //     width="20"
  //     height="20"
  //     viewBox="0 0 20 20"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       d="M4 14.5V8H5.5V14.5H4ZM9.25 14.5V8H10.75V14.5H9.25ZM2 17V15.5H18V17H2ZM14.5 14.5V8H16V14.5H14.5ZM2 7V5L10 1L18 5V7H2Z"
  //       fill={isSelected ? '#3E4AE7' : '#737373'}
  //     />
  //   </svg>
  // ),
});

const CATEGORY_FILTERS = () => [
  {
    label: 'Earnings',
    value: 'earnings',
  },
  {
    label: 'Deductions',
    value: 'employee_deductions',
  },
  {
    label: 'Contributions',
    value: 'employer_contributions',
  },
  // {
  //   label: 'Taxes',
  //   value: 'taxes',
  // },
];
const FILTERS = (selectedFilter?: string) => [
  {
    label: 'All Labels',
    value: 'all_items',
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 7.5V6H17V7.5H6ZM6 10.75V9.25H17V10.75H6ZM6 14V12.5H17V14H6ZM3.75 7.5C3.55556 7.5 3.38194 7.42361 3.22917 7.27083C3.07639 7.11806 3 6.94097 3 6.73958C3 6.53819 3.07639 6.36458 3.22917 6.21875C3.38194 6.07292 3.55903 6 3.76042 6C3.96181 6 4.13542 6.07187 4.28125 6.21562C4.42708 6.35937 4.5 6.5375 4.5 6.75C4.5 6.94444 4.42812 7.11806 4.28437 7.27083C4.14062 7.42361 3.9625 7.5 3.75 7.5ZM3.75 10.75C3.55556 10.75 3.38194 10.6736 3.22917 10.5208C3.07639 10.3681 3 10.191 3 9.98958C3 9.78819 3.07639 9.61458 3.22917 9.46875C3.38194 9.32292 3.55903 9.25 3.76042 9.25C3.96181 9.25 4.13542 9.32188 4.28125 9.46563C4.42708 9.60938 4.5 9.7875 4.5 10C4.5 10.1944 4.42812 10.3681 4.28437 10.5208C4.14062 10.6736 3.9625 10.75 3.75 10.75ZM3.75 14C3.55556 14 3.38194 13.9236 3.22917 13.7708C3.07639 13.6181 3 13.441 3 13.2396C3 13.0382 3.07639 12.8646 3.22917 12.7188C3.38194 12.5729 3.55903 12.5 3.76042 12.5C3.96181 12.5 4.13542 12.5719 4.28125 12.7156C4.42708 12.8594 4.5 13.0375 4.5 13.25C4.5 13.4444 4.42812 13.6181 4.28437 13.7708C4.14062 13.9236 3.9625 14 3.75 14Z"
          fill={selectedFilter === 'all_items' ? '#3E4AE7' : '#737373'}
        />
      </svg>
    ),
    countFunction: (_: Map<Category, Item[]>, labels: Label[]) => {
      return labels.length;
    },
  },
  // {
  //   label: 'All Mapped',
  //   value: 'all_mapped',
  //   icon: (
  //     <svg
  //       width="20"
  //       height="20"
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M8.9375 13L13.8958 8.0625L12.8333 7L8.9375 10.875L7.16667 9.125L6.10417 10.1875L8.9375 13ZM3 17V3H17V17H3Z"
  //         fill={selectedFilter === 'all_mapped' ? '#3E4AE7' : '#737373'}
  //       />
  //     </svg>
  //   ),
  //   countFunction: (categories: Map<Category, Item[]>) => {
  //     // toDo: Determine how we are going to leverage required count here
  //     const allItems = (
  //       flatten(Object.values(Object.fromEntries(categories))) as Item[]
  //     )
  //       .filter(
  //         (item) =>
  //           item.attributes.metadata &&
  //           item.attributes.metadata._PENSION_PRO_COLUMN,
  //       )
  //       .map((item) => item.attributes.metadata._PENSION_PRO_COLUMN);

  //     return new Set(allItems).size;
  //   },
  // },
  // {
  //   label: 'All Unmapped',
  //   value: 'all_unmapped',
  //   icon: (
  //     <svg
  //       width="20"
  //       height="20"
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M6.125 17V15.5H7.625V17H6.125ZM3 4.5V3H4.5V4.5H3ZM6.125 4.5V3H7.625V4.5H6.125ZM9.25 17V15.5H10.75V17H9.25ZM9.25 4.5V3H10.75V4.5H9.25ZM12.375 4.5V3H13.875V4.5H12.375ZM12.375 17V15.5H13.875V17H12.375ZM15.5 4.5V3H17V4.5H15.5ZM3 17V15.5H4.5V17H3ZM3 13.875V12.375H4.5V13.875H3ZM3 10.75V9.25H4.5V10.75H3ZM3 7.625V6.125H4.5V7.625H3ZM15.5 17V15.5H17V17H15.5ZM15.5 13.875V12.375H17V13.875H15.5ZM15.5 10.75V9.25H17V10.75H15.5ZM15.5 7.625V6.125H17V7.625H15.5Z"
  //         fill={selectedFilter === 'all_unmapped' ? '#3E4AE7' : '#737373'}
  //       />
  //     </svg>
  //   ),
  //   countFunction: (categories: Map<Category, Item[]>, labels: Label[]) => {
  //     // toDo: Determine how we are going to leverage required count here
  //     const allItems = (
  //       flatten(Object.values(Object.fromEntries(categories))) as Item[]
  //     )
  //       .filter(
  //         (item) =>
  //           item.attributes.metadata &&
  //           item.attributes.metadata._PENSION_PRO_COLUMN,
  //       )
  //       .map((item) => item.attributes.metadata._PENSION_PRO_COLUMN);

  //     return labels.length - new Set(allItems).size;
  //   },
  // },
];

export type PayStatementFilterRowProps = {
  icon: React.ReactNode;
  label: string;
  value: string;
  selected: boolean;
  count: number;
  onClick: () => void;
};

export function PayStatementFilterRow({
  icon,
  label,
  selected,
  count,
  onClick,
}: PayStatementFilterRowProps) {
  return (
    <Flex
      direction={'row'}
      justify={'space-between'}
      grow={1}
      cursor={'pointer'}
      onClick={onClick}
      borderRadius={'8px'}
      backgroundColor={'transparent'}
    >
      <Flex direction={'row'} grow={1} justify={'space-between'}>
        <Flex direction={'row'} gap={2} align={'center'}>
          {icon}
          <Text
            fontSize="14px"
            fontWeight={selected ? 600 : 400}
            color={selected ? '#3E4AE7' : '#737373'}
            whiteSpace={'nowrap'}
          >
            {label}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontSize="14px"
            fontWeight={selected ? 600 : 400}
            color={selected ? '#3E4AE7' : '#737373'}
          >
            {count}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export function PayStatementCategorySelector({
  labels,
  categoryMap,
  selectedCategory,
  setSelectedCategory,
  selectedFilter,
  setSelectedFilter,
}: {
  labels: Label[];
  selectedCategory: Category | null;
  categoryMap: Map<Category, Item[]>;
  setSelectedCategory: (category: Category | null) => void;
  selectedFilter?: string;
  setSelectedFilter: (filter: FilterType) => void;
}) {
  return (
    <ContentBox
      paddingX="16px"
      paddingY="16px"
      minWidth={'300px'}
      height={'fit-content'}
    >
      <Stack gap={8}>
        <Stack gap={3}>
          {FILTERS(selectedFilter).map((filter) => (
            <PayStatementFilterRow
              key={filter.value}
              {...filter}
              selected={selectedFilter === filter.value}
              count={filter.countFunction(categoryMap, labels)}
              onClick={() => setSelectedFilter(filter.value as FilterType)}
            />
          ))}
        </Stack>
        <Stack gap={3}>
          <Caption fontSize="12px" color={'#737373'}>
            Categories
          </Caption>
          {CATEGORY_FILTERS().map((category) => (
            <PayStatementFilterRow
              key={category.value}
              {...category}
              icon={
                CATEGORY_ICON_MAP(selectedCategory === category.value)[
                  category.value
                ]
              }
              selected={selectedCategory === category.value}
              count={
                labels.filter((x) => x.category === category.value).length ?? 0
              }
              onClick={() => {
                if (selectedCategory !== category.value) {
                  setSelectedCategory(category.value as Category);
                }
              }}
            />
          ))}
        </Stack>
      </Stack>
    </ContentBox>
  );
}
