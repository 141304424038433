import { Flex, useDisclosure } from '@chakra-ui/react';
import { COLORS } from '../../constant';

import { useFlag } from '@unleash/proxy-client-react';
import { useApplication } from 'applications/hooks';
import { ActionMenu } from 'components/ActionMenu';
import { useConnectSession } from 'connections-v2/hooks/useConnectSession';
import { FeatureFlag } from 'constant/feature-flags';
import { useState } from 'react';
import { LinkIcon } from 'shared/icons/Link';
import { DisconnectedIcon } from '../../shared/icons/DisconnectedIcon';
import { useConnectionConfiguration } from '../hooks/useConnectionConfiguration';
import {
  useConnectionPayStatementItemLink,
  useFetchPayStatementMappingLink,
} from '../hooks/useConnectionPayStatementItemLink';
import { ConnectionAccountDetail } from '../types';
import { CreateEmailLinkModal } from './CreateEmailLinkModal';
import { CreatePayStatementLinkModal } from './CreatePayStatementLinkModal';
import { DisconnectConfirmationModal } from './DisconnectConfirmationModal';

const RefreshIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 16C8.33333 16 6.91667 15.4167 5.75 14.25C4.58333 13.0833 4 11.6667 4 10C4 8.33333 4.58333 6.91667 5.75 5.75C6.91667 4.58333 8.33333 4 10 4C10.9028 4 11.7396 4.1875 12.5104 4.5625C13.2812 4.9375 13.9444 5.4375 14.5 6.0625V4H16V9H11V7.5H13.7292C13.3264 6.88889 12.7986 6.40278 12.1458 6.04167C11.4931 5.68056 10.7778 5.5 10 5.5C8.75 5.5 7.6875 5.9375 6.8125 6.8125C5.9375 7.6875 5.5 8.75 5.5 10C5.5 11.25 5.9375 12.3125 6.8125 13.1875C7.6875 14.0625 8.75 14.5 10 14.5C11.1667 14.5 12.1667 14.1146 13 13.3438C13.8333 12.5729 14.3125 11.625 14.4375 10.5H15.9792C15.8542 12.0556 15.2222 13.3611 14.0833 14.4167C12.9444 15.4722 11.5833 16 10 16Z"
        fill="#737373"
      />
    </svg>
  );
};

const SendPaymentLink = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 16V4L18 10L3 16ZM4.5 13.7708L13.9583 10L4.5 6.22917V8.5L9 10L4.5 11.5V13.7708Z"
        fill="#737373"
      />
    </svg>
  );
};

export const ConnectionDetailActionMenu = ({
  connectionDetail,
}: {
  connectionDetail: ConnectionAccountDetail | null;
}) => {
  const {
    isOpen: isDisconnectModalOpen,
    onOpen: onDisconnectModalOpen,
    onClose: onDisconnectModalClose,
  } = useDisclosure();

  const { application } = useApplication();
  const isFlatfileEnabled = application?.features.flatFileEnabled;
  const isConnectionDisconnectEnabled = useFlag(
    FeatureFlag.DashboardConnectionDisconnect,
  );
  const payStatementReverseMapping = useFlag(
    FeatureFlag.PayStatementReverseMapping,
  );

  const { connectionConfiguration } = useConnectionConfiguration({
    applicationId: application?.id ?? '',
    connectionId: connectionDetail?.connectionAccountId ?? '',
    thirdParty: 'pensionPro',
  });

  const isPayStatementMappingEnabled =
    (useFlag(FeatureFlag.PayStatementMapping) ||
      (connectionConfiguration && connectionConfiguration.enabled)) &&
    payStatementReverseMapping;

  const {
    isOpen: isNewConnectSessionOpen,
    onOpen: onNewConnectSessionOpen,
    onClose: onNewConnectSessionClose,
  } = useDisclosure();

  const {
    create: { mutate, data, isPending },
    list: { data: connectSessions },
  } = useConnectSession();

  const { data: payStatementMappingLinkData } = useFetchPayStatementMappingLink(
    {
      applicationId: application?.id ?? '',
      connectionId: connectionDetail?.connectionAccountId ?? '',
    },
  );

  const [linkUrl, setLinkUrl] = useState<string | null>(null);

  const [emailModalOpen, setEmailModalOpen] = useState(false);

  const [payStatementLinkModalOpen, setPayStatementLinkModalOpen] =
    useState(false);

  const latestSession = connectSessions?.[0];

  const onConnectionDisconnectRequested = () => {
    onDisconnectModalOpen();
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendPayStatementMappingLink = useConnectionPayStatementItemLink(() => {
    setIsSubmitting(false);
    setPayStatementLinkModalOpen(false);
  });

  const onSendPayStatementMappingLink = ({ email }: { email: string }) => {
    if (application && connectionDetail) {
      sendPayStatementMappingLink.mutate({
        applicationId: application.id,
        connectionId: connectionDetail.connectionAccountId,
        recipient: email,
      });
    }
  };

  const onCreateNewConnectSession = () => {
    if (connectionDetail) {
      mutate(
        {
          connectionId: connectionDetail.connectionAccountId,
          providerId: connectionDetail.providerId,
          scopes: connectionDetail.products.flatMap(
            (product) => product.scopes,
          ),
          externalCompanyId: null,
          externalCompanyName: null,
        },
        { onSuccess: onNewConnectSessionOpen },
      );
    }
  };

  const [emailSent, setEmailSent] = useState(false);

  return (
    <>
      {connectionDetail && (
        <DisconnectConfirmationModal
          isOpen={isDisconnectModalOpen}
          onClose={onDisconnectModalClose}
          connectionId={connectionDetail.connectionAccountId}
          companyName={connectionDetail.companyName}
          clientId={connectionDetail.clientId}
          onConnectionDisconnected={() => {}}
        />
      )}
      {/* One of features have to be enabled to show the menu */}
      {(isPayStatementMappingEnabled || isFlatfileEnabled) && (
        <ActionMenu
          isLoading={isPending}
          menuItems={[
            ...(isFlatfileEnabled
              ? [
                  {
                    icon: <RefreshIcon />,
                    label:
                      latestSession && !latestSession.tokenId
                        ? 'Refresh Latest Connect Link'
                        : 'Create a Re-authentication Connect Link',
                    onClick: onCreateNewConnectSession,
                  },
                ]
              : []),
            ...(isPayStatementMappingEnabled
              ? [
                  {
                    icon: <SendPaymentLink />,
                    label: 'Send a Payroll Mapping Link',
                    onClick: () => setPayStatementLinkModalOpen(true),
                  },
                ]
              : []),
            ...(isConnectionDisconnectEnabled
              ? [
                  {
                    icon: <DisconnectedIcon />,
                    label: 'Disconnect',
                    onClick: onConnectionDisconnectRequested,
                    color: COLORS.FINCH.RED,
                    bgHoverColor: COLORS.LIGHT_RED,
                    textHoverColor: COLORS.FINCH.RED,
                  },
                ]
              : []),
          ]}
        />
      )}
      {payStatementLinkModalOpen && (
        <CreatePayStatementLinkModal
          isOpen={payStatementLinkModalOpen}
          isSubmitting={isSubmitting}
          onClose={() => {
            setPayStatementLinkModalOpen(false);
            setEmailSent(false);
          }}
          onCancel={() => {
            setPayStatementLinkModalOpen(false);
            setEmailSent(false);
          }}
          onSave={(email) => {
            setIsSubmitting(true);
            setEmailSent(true);
            onSendPayStatementMappingLink({
              email,
            });
          }}
          onOpenEmailModal={() => {
            setPayStatementLinkModalOpen(false);
            setEmailModalOpen(true);
          }}
          linkUrl={emailSent ? payStatementMappingLinkData : ''}
        />
      )}
      {emailModalOpen && (
        <CreateEmailLinkModal
          isOpen={emailModalOpen}
          onClose={() => setEmailModalOpen(false)}
          onCancel={() => setEmailModalOpen(false)}
          onSave={() => setEmailModalOpen(false)}
          onSendViaEmail={(e) =>
            onSendPayStatementMappingLink({
              email: e,
            })
          }
          linkUrl={payStatementMappingLinkData ?? ''}
        />
      )}
    </>
  );
};
