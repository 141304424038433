import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useConnectionDetailApi } from './useConnectionDetailApi';

export const useFetchPayStatementMappingLink = ({
  applicationId,
  connectionId,
}: {
  applicationId: string;
  connectionId: string;
}) => {
  const api = useConnectionDetailApi();

  const query = useQuery({
    queryFn: () =>
      api.fetchPayStatementMappingLink(applicationId, connectionId),
    queryKey: ['payStatementMappingLink', applicationId, connectionId],
  });

  return query;
};

export const useConnectionPayStatementItemLink = (
  onSuccessCallback: () => void,
) => {
  const api = useConnectionDetailApi();
  const toast = useToast({
    duration: 3000,
    isClosable: true,
  });

  const mutation = useMutation({
    mutationFn: ({
      applicationId,
      connectionId,
      recipient,
    }: {
      applicationId: string;
      connectionId: string;
      recipient: string;
    }) => {
      return api.sendPayStatementMappingLink(
        applicationId,
        connectionId,
        recipient,
      );
    },
    onError: () => {
      toast({
        description: 'Error sending payroll mapping link',
        status: 'error',
      });
    },
    onSuccess: async () => {
      toast({
        description: 'Link sent to employer!',
        status: 'success',
      });
      onSuccessCallback();
    },
  });

  return mutation;
};
