import React from 'react';

export const SendViaEmail = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="32" fill="#EFF1FF" />
      <path
        d="M16.1667 44.5V19.5H47.8333V44.5H16.1667ZM32 32.4788L18.2608 23.485V42.4058H45.7392V23.485L32 32.4788ZM32 30.2883L45.4037 21.5942H18.6237L32 30.2883ZM18.2608 23.485V21.5942V42.4058V23.485Z"
        fill="#3E4AE7"
      />
    </svg>
  );
};
