import { Stack } from '@chakra-ui/react';
import 'react-calendar/dist/Calendar.css';
import ReactDatePicker from 'react-datepicker';
import { CalendarContainer } from '../../../../connections/components/DatePickerTheme';
import { BsArrowRight } from 'react-icons/bs';

const ArrowRight = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      style={{
        position: 'relative',
        right: '10px',
        bottom: '1px',
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6L6 12L4.9375 10.9375L9.125 6.75L6.26056e-08 6.75L8.04929e-08 5.25L9.125 5.25L4.9375 1.0625L6 7.15493e-08L12 6Z"
        fill="#737373"
      />
    </svg>
  );
};

const ArrowLeft = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-7.15493e-08 6L6 12L7.0625 10.9375L2.875 6.75L12 6.75L12 5.25L2.875 5.25L7.0625 1.0625L6 7.15493e-08L-7.15493e-08 6Z"
        fill="#737373"
      />
    </svg>
  );
};

export const PayStatementInputCalendar = (props: {
  value: string;
  onChange: (value: Date) => void;
}) => {
  return (
    <Stack>
      <style>
        {`
        .react-datepicker {
          width: 100% !important;
          border-top-right-radius: 0px;
          border-top-left-radius: 0px;
          position: relative;
          border: 1px solid #D9D9D9;
          border-top: 0px;
          bottom: 4px;
        }
        .react-datepicker__navigation-icon--next::before {
          display: none;
        }
        .react-datepicker__navigation-icon--previous::before {
          display: none;
        }
        .react-datepicker__navigation {
          top: 20px !important;
        }
        .react-datepicker__navigation--next {
          right: 10px !important;
        }
        .react-datepicker__navigation--previous {
          left: 10px !important;
        }
        .react-datepicker__month-container {
          width: 100% !important;
          padding: 10px;
        }
        .react-datepicker__day--keyboard-selected {
          border-radius: 32px !important;
          height: 32px !important;
          width: 32px !important;
          background: #EFF1FF !important;
          color: #3E4AE7 !important;
          font-weight: 600 !important;
        }
        .react-datepicker__day--selected {
          border-radius: 20px !important;
          background: #EFF1FF !important;
          color: #3E4AE7 !important;
          font-weight: 600 !important;
        }
        .react-datepicker__day:hover {
          border-radius: 20px !important;
        }
        .react-datepicker__day-names {
          display: flex;
          padding: 0px 15px;
          color: #737373;
          justify-content: space-between;
        }
        .react-datepicker__day {
          height: 32px !important;
          width: 32px !important;
          max-width: 32px !important;
          max-height: 32px !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .react-datepicker__header {
          background: white;
          padding-bottom: 10px;
          border-bottom: 0px !important;
        }
        .react-datepicker__current-month {
          font-weight: 500 !important;
          padding-bottom: 15px;
        }
        .react-datepicker__day-name {
          width: fit-content;
          color: #737373;
        }
        .react-datepicker__week {
          display: flex;
          justify-content: space-between;
        }
        .react-datepicker__day {
          flex-grow: 1;
        }
        .react-datepicker__day--outside-month {
          color: transparent !important;
          pointer-events: none;
        }
      `}
      </style>
      <ReactDatePicker
        placeholderText="MM/DD/YYYY"
        dateFormat="MM/dd/yyyy"
        selected={props.value ? new Date(props.value) : undefined}
        value={props.value ? props.value : undefined}
        disabledKeyboardNavigation
        inline
        nextMonthButtonLabel={<ArrowRight />}
        previousMonthButtonLabel={<ArrowLeft />}
        onChange={(date) => {
          const val = date ? new Date(date?.toLocaleString()) : undefined;

          if (val) {
            props.onChange(val);
          }
        }}
        calendarContainer={CalendarContainer}
      />
    </Stack>
  );
};
