export enum FeatureFlag {
  ClearAcfStatus = 'clearAcfStatus',
  DashboardConnectionsV2 = 'dashboardConnectionsV2',
  DashboardApiExplorer = 'dashboardApiExplorer',
  DashboardConnectionDisconnect = 'dashboardConnectionDisconnect',
  PayStatementMapping = 'payStatementMapping',
  DashboardSsoSelfService = 'dashboardSsoSelfService',
  PayStatementReverseMapping = 'payStatementReverseMapping',
  PayStatementMappingGusto = 'payStatementMappingGusto',
}
