import React from 'react';

export const Lock = () => {
  return (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="64" height="64" rx="32" fill="#EFF1FF" />
      <path
        d="M20.8845 51C20.0982 51 19.4208 50.7158 18.8525 50.1475C18.2842 49.5792 18 48.9018 18 48.1155V28.108C18 27.3113 18.2842 26.6313 18.8525 26.068C19.4208 25.5047 20.0982 25.223 20.8845 25.223H24.3655V20.6345C24.3655 18.2382 25.2062 16.2 26.8875 14.52C28.5688 12.84 30.6085 12 33.0065 12C35.4048 12 37.4423 12.84 39.119 14.52C40.796 16.2 41.6345 18.2382 41.6345 20.6345V25.223H45.1155C45.9018 25.223 46.5792 25.5047 47.1475 26.068C47.7158 26.6313 48 27.3113 48 28.108V48.1155C48 48.9018 47.7158 49.5792 47.1475 50.1475C46.5792 50.7158 45.9018 51 45.1155 51H20.8845ZM20.8845 48.7305H45.1155C45.2948 48.7305 45.4422 48.6728 45.5575 48.5575C45.6728 48.4422 45.7305 48.2948 45.7305 48.1155V28.108C45.7305 27.9283 45.6728 27.7808 45.5575 27.6655C45.4422 27.5502 45.2948 27.4925 45.1155 27.4925H20.8845C20.7052 27.4925 20.5578 27.5502 20.4425 27.6655C20.3272 27.7808 20.2695 27.9283 20.2695 28.108V48.1155C20.2695 48.2948 20.3272 48.4422 20.4425 48.5575C20.5578 48.6728 20.7052 48.7305 20.8845 48.7305ZM33.0085 41.5C33.9412 41.5 34.7377 41.1745 35.398 40.5235C36.0583 39.8725 36.3885 39.089 36.3885 38.173C36.3885 37.2883 36.0555 36.4922 35.3895 35.7845C34.7238 35.0768 33.9245 34.723 32.9915 34.723C32.0588 34.723 31.2623 35.0768 30.602 35.7845C29.9417 36.4922 29.6115 37.2967 29.6115 38.198C29.6115 39.0993 29.9445 39.875 30.6105 40.525C31.2762 41.175 32.0755 41.5 33.0085 41.5ZM26.6345 25.223H39.3655V20.6345C39.3655 18.8665 38.7473 17.3637 37.511 16.126C36.2747 14.8883 34.7733 14.2695 33.007 14.2695C31.2407 14.2695 29.737 14.8883 28.496 16.126C27.255 17.3637 26.6345 18.8665 26.6345 20.6345V25.223Z"
        fill="#3E4AE7"
      />
    </svg>
  );
};
