import { ChevronDownIcon } from '../../../../shared/icons/ChevronDownIcon';
import { COLORS } from '../../../../constant';
import { Checkbox, Flex, Text } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { default as ReactSelect, components } from 'react-select';
import type { ActionMeta, OptionProps } from 'react-select';
import _ from 'lodash';
export type OptionSelect = {
  label: string;
  value: string;
  isDisabled?: boolean;
  disabledValue?: string;
  data?: {
    disabledValue?: string;
  };
};

const Option = (props: {
  isSelected: boolean;
  label: string;
  isDisabled: boolean;
  disabledValue?: string;
  data?: {
    disabledValue?: string;
  };
}) => {
  return (
    <div>
      <components.Option {...(props as OptionProps)}>
        <Flex
          direction={'row'}
          alignItems={'center'}
          width={'100%'}
          justifyContent={'space-between'}
        >
          <Flex direction={'row'} gap={2} opacity={props.isDisabled ? 0.5 : 1}>
            <Checkbox
              type="checkbox"
              isChecked={props.isSelected}
              pointerEvents={'none'}
            />
            <Text
              color={props.isDisabled ? '#737373' : '#000000'}
              fontSize={'13px'}
            >
              {props.label}
            </Text>
          </Flex>
          <Text color={'#737373'} fontSize={'12px'}>
            {props.isDisabled &&
              `Mapped to ${
                (
                  props.data as {
                    data: {
                      disabledValue?: string;
                    };
                  }
                ).data.disabledValue
              }`}
          </Text>
        </Flex>
      </components.Option>
    </div>
  );
};

export function PayStatementMappingMultiSelect({
  options,
  selected,
  disabled,
  onChange,
  allSelected,
  currentName,
}: {
  options: OptionSelect[];
  selected: OptionSelect[];
  disabled: boolean;
  onChange: (selected: OptionSelect[], meta: ActionMeta<OptionSelect>) => void;
  allSelected?: Record<string, string | null | undefined>;
  currentName: string;
}) {
  const [state, setState] = useState({ optionSelected: selected });

  const optionRef = useRef<OptionSelect[]>([]);

  const handleChange = (
    selected: OptionSelect[],
    meta: ActionMeta<OptionSelect>,
  ) => {
    setState({
      optionSelected: selected,
    });

    onChange(selected, meta);
  };

  return (
    <Flex width={'100%'} position={'relative'}>
      {state.optionSelected.length > 0 && (
        <Flex
          style={{
            padding: '5px',
            background: disabled ? 'transparent' : 'white',
            position: 'absolute',
            left: '2px',
            top: '2px',
            zIndex: 1,
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
          }}
        >
          <Flex
            style={{
              backgroundColor: COLORS.FINCH.PURPLE,
              zIndex: 1,
              color: COLORS.WHITE,
              borderRadius: '20px',
              width: '24px',
              height: '24px',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
            }}
          >
            {state.optionSelected.length}
          </Flex>
        </Flex>
      )}
      <ReactSelect
        options={options.map((e) => ({
          isDisabled: ((allSelected?.[e.value] &&
            currentName !== allSelected?.[e.value]) ??
            false) as boolean,
          label: e.label,
          data: {
            disabledValue: e.disabledValue,
          },
          value: e.value,
        }))}
        isMulti
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        hideSelectedOptions={false}
        styles={{
          menu: (base) => ({
            ...base,
            zIndex: 99999,
            padding: '4px',
          }),
          container: (base, { isDisabled }) => ({
            ...base,
            width: '100%',
            borderRadius: '8px',
            height: '40px',
            maxHeight: '40px',
            background: 'white',
            ...(isDisabled && {
              background: '#F4F4F4',
              pointerEvents: 'none',
            }),
          }),
          valueContainer: (base, { isDisabled }) => ({
            ...base,
            flexWrap: 'nowrap',
            background: 'white',
            height: '35px',
            maxHeight: '35px',
            width: '400px',
            paddingBottom: '0px',
            paddingLeft: state.optionSelected.length > 0 ? '35px' : '10px',
            ...(isDisabled && {
              background: 'none',
              pointerEvents: 'none',
            }),
          }),
          control: (base, { isDisabled }) => ({
            ...base,
            borderRadius: '8px',
            padding: '2px',
            height: '40px',
            maxHeight: '40px',
            borderColor: COLORS.GRAY.GRAY_400,
            background: 'white',
            boxShadow: 'none',
            ...(isDisabled && {
              background: '#f4f4f4',
              borderColor: '#d9d9d9',
              pointerEvents: 'none',
            }),
            '&:hover': {
              border: '1px solid #3e4ae7 !important',
            },
            '&:focus': {
              border: '1px solid #3e4ae7 !important',
            },
            '&:active': {
              border: '1px solid #3e4ae7 !important',
            },
          }),
          input: (base) => ({
            ...base,
            position: 'relative',
            bottom: '2px',
          }),
          placeholder: (base) => ({
            ...base,
            position: 'relative',
            bottom: '2px',
            fontSize: '14px',
          }),
          multiValue: (base, { isDisabled }) => ({
            ...base,
            backgroundColor: '#F4F4F4',
            color: '#161616',
            height: 'fit-content',
            width: 'fit-content',
            minWidth: 'fit-content',
            borderRadius: '4px',
            position: 'relative',
            padding: '0px 2px',
            paddingRight: '4px',
            fontSize: '14px',
            bottom: '1.5px',
            ...(isDisabled && {
              background: '#e2e2e2',
              color: '#737373',
              '& > div': {
                color: '#737373',
              },
            }),
          }),
          indicatorSeparator: (base, props) => {
            const totalWidth = _.sum(
              props.getValue().map((e) => e.label.length * 6),
            );

            const shouldShowShadow = totalWidth > 300 && !props.isFocused;

            return {
              ...base,
              borderColor: 'transparent',
              backgroundColor: 'transparent',
              zIndex: 1,
              boxShadow: shouldShowShadow
                ? '-2px -1px 3px 0px rgba(83, 83, 83, 0.65)'
                : 'none',
            };
          },
          multiValueLabel: (base) => ({
            ...base,
            width: '100%',
          }),
          multiValueRemove: (base) => ({
            ...base,
            '&:hover': {
              backgroundColor: 'transparent',
              cursor: 'pointer',
              color: '#3e4ae7',
              stroke: '#3e4ae7',
            },
          }),
          option: (base, { isDisabled }) => ({
            ...base,
            ...(isDisabled && {
              pointerEvents: 'none',
            }),
            padding: '5px 10px',
            backgroundColor: '#FFFF',
            '&:active': {
              backgroundColor: '#F4F4F4',
            },
          }),
          indicatorsContainer: (base) => ({
            ...base,
            width: 'fit-content',
            minWidth: 'fit-content',
          }),
        }}
        components={{
          Option,
          DropdownIndicator: () => (
            <div
              style={{
                padding: '10px',
              }}
            >
              <ChevronDownIcon style={{ color: '#737373' }} />
            </div>
          ),
          ClearIndicator: () => null,
          MultiValueRemove: () => null,
        }}
        onChange={(e, props) => {
          handleChange(e as OptionSelect[], props);
        }}
        value={state.optionSelected}
        isDisabled={disabled}
      />
    </Flex>
  );
}
