import {
  Button,
  Flex,
  Input,
  ModalFooter,
  Stack,
  Text,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';

import { useState } from 'react';
import { ModalTemplate } from './ModalTemplate';
import { SendLinkSVG } from './SendLinkSVG';

export const CreateEmailLinkModal = ({
  isOpen,
  onClose,
  onSendViaEmail,
}: {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  onSave: () => void;
  onSendViaEmail: (e: string) => void;
  linkUrl: string;
}) => {
  const [email, setEmail] = useState('');

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <ModalFooter width={'100%'}>
          <Stack direction={'column'} width={'100%'} gap={8}>
            <Button
              disabled={email.length === 0}
              variant="solid"
              onClick={() => {
                if (email) {
                  onSendViaEmail(email);
                }
              }}
              background={COLORS.FINCH.BLACK}
              _hover={{
                background: COLORS.FINCH.BLACK,
              }}
              color={COLORS.WHITE}
            >
              Send Email
            </Button>
          </Stack>
        </ModalFooter>
      }
    >
      <Flex direction={'column'} gap={2} alignItems={'center'}>
        <SendLinkSVG />
        <Text fontSize={'20px'} fontWeight={'600'}>
          Email Payroll Mapping
        </Text>
        <Text textAlign={'center'}>
          Email this URL to your customer to have them complete the mapping
        </Text>
        <Flex paddingY={4} width={'100%'}>
          <Input
            value={email}
            placeholder="Recipient Email"
            onChange={(e) => setEmail(e.target.value)}
            style={{
              fontSize: '14px',
              color: COLORS.FINCH.GRAY,
              paddingLeft: '10px',
              paddingRight: '30px',
              margin: '0px',
            }}
          />
        </Flex>
      </Flex>
    </ModalTemplate>
  );
};
