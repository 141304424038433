import { Box, Flex, HStack, Stack, Text, Tooltip } from '@chakra-ui/react';

import { ContentBox } from '../../../../components/ContentBox';
import { categoryLabel } from '../constants';
import { Category, Item, Option } from '../types';
import { PayStatementCategoryExpand } from './pay-statement-category-expand';
import { PayStatementCategoryRowV2 } from './pay-statement-category-row-v2';
import { InfoIcon } from '../../../../shared/InfoIcon';
import { OptionSelect } from './pay-statement-mapping-multi-select';
import type { ActionMeta } from 'react-select';
import { COLORS } from '../../../../constant';
import { useState } from 'react';

function PayStatementCategoryGroupColumnsV2({ header }: { header: Category }) {
  return (
    <HStack paddingX="24px" paddingY="12px" gap="32px">
      <Box width="100%">
        <Flex gap="8px">
          <Text fontSize="sm" fontWeight={500}>
            Label
          </Text>
        </Flex>
      </Box>
      <Box width="100%" maxWidth="580px">
        <Flex gap="8px">
          <Text fontSize="sm" fontWeight={500}>
            Payroll Items
          </Text>
        </Flex>
      </Box>
      {header === 'earnings' ? (
        <Flex gap="4px" minWidth="73px">
          <Text fontSize="sm" fontWeight={500}>
            Eligible
          </Text>
          <Tooltip
            placement="top"
            label="Choose whether this Earnings item is eligible in this employer’s plan or not"
            shouldWrapChildren
          >
            <InfoIcon />
          </Tooltip>
        </Flex>
      ) : null}
    </HStack>
  );
}

export type PayStatementCategoryGroupV2Props = {
  editing: boolean;
  header: Category;
  items: Item[];
  labels: Option[];
  checked: Partial<Record<Category, Record<string, boolean>>>;
  values: Partial<Record<Category, Record<string, string | null | undefined>>>;
  onChange: (
    label: string,
    item: Item[],
    meta: ActionMeta<OptionSelect>,
  ) => void;
  disabled: boolean;
  onToggle: (items: Item[], polarity: boolean) => void;
};

export function PayStatementCategoryGroupV2({
  header,
  items,
  labels,
  editing,
  disabled,
  checked,
  onChange,
  onToggle,
  values,
}: PayStatementCategoryGroupV2Props) {
  const associatedValues = labels
    .filter(({ category }) => category && category === header)
    .map(({ value }) => value);

  const generateProps = (
    labelValue: string,
    items: Item[],
    onToggle: (items: Item[], polarity: boolean) => void,
    header: Category,
  ) => {
    const opts = Object.keys(values[header] ?? {}).sort((a, b) => {
      if (a > b) {
        return 1;
      }

      if (a < b) {
        return -1;
      }

      return 0;
    });
    return {
      options: opts.map((value) => ({
        key: value ?? '',
        value: value ?? '',
        isDisabled: values[header]?.[value] === null,
      })),
      values: opts.filter(
        (e) => values[header]?.[e] === labelValue,
      ) as Array<string>,
      valueMap: values,
      editing: editing,
      disabled: disabled,
      name: labelValue ?? '',
      checked: !!items
        .filter((e) => e.category === header)
        .filter((e) => values?.[e.category]?.[e.name] === labelValue)
        .find((e) => {
          return checked?.[e.category]?.[e.name];
        }),
      onToggle: (polarity: boolean) => {
        onToggle(
          items
            .filter((e) => e.category === header)
            .filter((e) => values?.[e.category]?.[e.name] === labelValue),
          polarity,
        );
      },
      onChange: (value: string[], meta: ActionMeta<OptionSelect>) => {
        onChange(
          labelValue ?? '',
          items.filter((e) => value.includes(e.name)),
          meta,
        );
      },
      header,
    };
  };

  const [categoryExpand, setCategoryExpand] = useState(true);

  return (
    <Stack gap="12px" marginBottom="16px">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        width={'100%'}
      >
        <Text fontSize="md" fontWeight={500}>
          {categoryLabel[header]}
        </Text>
        <Text fontSize="sm" fontWeight={400} color={COLORS.GRAY.GRAY_500}>
          Showing{' '}
          {categoryExpand
            ? associatedValues.length
            : associatedValues.slice(0, 3).length}{' '}
          of {associatedValues.length} labels
        </Text>
      </Flex>
      <ContentBox>
        <PayStatementCategoryGroupColumnsV2 header={header} />
        {associatedValues.slice(0, 3).map((labelValue, i) => (
          <PayStatementCategoryRowV2
            key={i}
            {...generateProps(labelValue ?? '', items, onToggle, header)}
          />
        ))}
        {associatedValues.length > 3 ? (
          <PayStatementCategoryExpand
            remainingItemCount={associatedValues.slice(3).length}
            setCategoryExpand={setCategoryExpand}
            categoryExpand={categoryExpand}
          >
            {associatedValues.slice(3).map((labelValue, i) => (
              <PayStatementCategoryRowV2
                key={i}
                {...generateProps(labelValue ?? '', items, onToggle, header)}
              />
            ))}
          </PayStatementCategoryExpand>
        ) : (
          ''
        )}
      </ContentBox>
    </Stack>
  );
}
