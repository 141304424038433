import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react';
import { z } from 'zod';
import { useRef } from 'react';

import { useConnectSession } from '../../hooks/useConnectSession';
import { ConnectUrlCopy } from './ConnectUrlCopy';
import { CreateSessionForm } from './CreateSessionForm';
import { Category } from '@/connection-detail-v2/pages/pay-statement-mapping/types';

export const newSessionSchema = z.object({
  externalCompanyId: z
    .string()
    .min(1, { message: 'The company ID is required' }),
  externalCompanyName: z
    .string()
    .min(1, { message: 'The company name is required' }),
  providerId: z.string().optional(),
  products: z.array(z.string()),
});

export const reauthSessionSchema = z.object({
  providerId: z.string(),
  connectionId: z.string().uuid(),
  externalCompanyId: z.string().nullish(),
  externalCompanyName: z.string().nullish(),
});

export type NewSession = z.infer<typeof newSessionSchema>;
export type ReauthSession = z.infer<typeof reauthSessionSchema>;

export const ModalNewConnectSession = ({
  isOpen,
  onClose,
  connectUrl,
  helperText,
}: {
  isOpen: boolean;
  onClose: () => void;
  connectUrl?: string;
  helperText?: string;
}) => {
  const {
    create: { mutate, isPending, data, error },
  } = useConnectSession();
  const firstInputRef = useRef<HTMLInputElement>(null);
  const url = connectUrl || data?.connectUrl;

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={firstInputRef}>
      <ModalOverlay />
      <ModalContent maxWidth="400px" minHeight="700px" borderRadius="20px">
        <ModalCloseButton />

        {url ? (
          <ConnectUrlCopy url={url} onClose={onClose} helperText={helperText} />
        ) : (
          <CreateSessionForm
            error={error}
            isPending={isPending}
            mutate={mutate}
            initialRef={firstInputRef}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
