import axios from 'axios';
import * as Sentry from '@sentry/react';

import {
  ConnectionAccountDetail,
  ConnectionConfiguration,
  PayStatementItem,
  PayStatementItemInput,
  PayStatementItemLabel,
} from './types';
import type { Auth0ContextInterface, User } from '@auth0/auth0-react';
import { JobStatusEntry } from '@finch-api/common/dist/external/dashboard/job-status';

export class ConnectionDetailApi {
  constructor(
    private baseUrl: string,
    private auth: Auth0ContextInterface<User>,
  ) {}

  getToken = async () => {
    const { getAccessTokenSilently } = this.auth;
    return getAccessTokenSilently();
  };

  async getConnectionDetail(
    applicationId: string,
    connectionId: string,
  ): Promise<ConnectionAccountDetail> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get<ConnectionAccountDetail>(
        uri.toString(),
        {
          headers: {
            Authorization: `Bearer ${await this.getToken()}`,
          },
        },
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async disconnect(applicationId: string, connectionId: string): Promise<void> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/disconnect`,
      this.baseUrl,
    );

    try {
      await axios.post(
        uri.toString(),
        {},
        {
          headers: {
            Authorization: `Bearer ${await this.getToken()}`,
          },
        },
      );
      return;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async jobs(
    applicationId: string,
    connectionId: string,
  ): Promise<JobStatusEntry[] | null> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/jobs`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
    return null;
  }

  async payStatementItem(
    applicationId: string,
    connectionId: string,
    options: { startDate?: Date } = {},
  ): Promise<PayStatementItem | null> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/pay-statement-item`,
      this.baseUrl,
    );

    for (const [key, value] of Object.entries(options)) {
      if (key === 'startDate') {
        const month = `${value.getMonth() + 1}`.padStart(2, '0');
        const day = `${value.getDate()}`.padStart(2, '0');

        uri.searchParams.set(key, `${value.getFullYear()}-${month}-${day}`);
      }
    }

    try {
      const { data } = await axios.get(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });

      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
    return null;
  }

  async payStatementItemLabels(
    applicationId: string,
    connectionId: string,
  ): Promise<PayStatementItemLabel[] | null> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/pay-statement-item/labels`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
    return null;
  }

  async createPayStatementLabels(
    applicationId: string,
    connectionId: string,
    labels: PayStatementItemInput[],
    code?: string,
  ): Promise<PayStatementItem | null> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/pay-statement-item${
        code ? `?code=${code}` : ''
      }`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.post(uri.toString(), labels, {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
    return null;
  }

  async fetchPayStatementMappingLink(
    applicationId: string,
    connectionId: string,
  ): Promise<any> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/pay-statement-item/link`,
      this.baseUrl,
    );

    return uri.toString();
  }

  async sendPayStatementMappingLink(
    applicationId: string,
    connectionId: string,
    recipient: string,
  ): Promise<any> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/pay-statement-item/link`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.post(
        uri.toString(),
        {
          recipient,
        },
        {
          headers: {
            Authorization: `Bearer ${await this.getToken()}`,
          },
        },
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async getConfiguration(
    applicationId: string,
    connectionId: string,
    thirdParty: 'pensionPro',
  ): Promise<ConnectionConfiguration | null> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/configuration/${thirdParty}`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
    return null;
  }
}
