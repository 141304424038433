import { ModalOverlay } from '@chakra-ui/react';

import { ModalCloseButton } from '@chakra-ui/react';

import { Modal } from '@chakra-ui/react';

import { ModalBody } from '@chakra-ui/react';

import { Flex } from '@chakra-ui/react';

import { ModalContent } from '@chakra-ui/react';

export const ModalTemplate = ({
  isOpen,
  onClose,
  children,
  footer,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  footer?: React.ReactNode;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent maxWidth="400px" minHeight="700px" borderRadius="20px">
        <ModalCloseButton />
        <ModalBody>
          <Flex
            direction="column"
            gap={4}
            paddingTop={10}
            paddingBottom={5}
            minHeight={'500px'}
            justifyContent={'space-between'}
          >
            {children}
          </Flex>
        </ModalBody>
        {footer}
      </ModalContent>
    </Modal>
  );
};
