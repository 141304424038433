import { Flex, Stack, Text } from '@chakra-ui/react';
import { ReactNode, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import {
  DownArrowIcon,
  RightArrowIcon,
} from '../../../../shared/icons/RightArrowIcon';
import { COLORS } from '../../../../constant';

type PayStatementCategoryExpandProps = {
  children: ReactNode;
  remainingItemCount: number;
  categoryExpand: boolean;
  setCategoryExpand: (value: boolean) => void;
};

export function PayStatementCategoryExpand(
  props: PayStatementCategoryExpandProps,
) {
  const ref = useRef<HTMLDivElement>(null);

  const [hovered, setHovered] = useState(false);

  return (
    <Stack>
      <div
        style={{
          overflow: props.categoryExpand ? 'visible' : 'hidden',
          height: props.categoryExpand ? 'fit-content' : 0,
        }}
      >
        <Stack width={'100%'} ref={ref}>
          {props.children}
        </Stack>
      </div>
      <Flex
        borderTop={'1px solid #dedede'}
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'row'}
        onClick={() => props.setCategoryExpand(!props.categoryExpand)}
        cursor={'pointer'}
        paddingY={2}
        gap={2}
        borderBottomRadius={'8px'}
        _hover={{
          background: '#FAFAFA',
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Text
          fontSize={'14px'}
          _hover={{
            color: '#161616',
          }}
          color={hovered ? '#161616' : '#666'}
        >
          {props.categoryExpand
            ? 'Show less'
            : `Show ${props.remainingItemCount} more`}
        </Text>
        <motion.div animate={{ rotate: props.categoryExpand ? 180 : 0 }}>
          <DownArrowIcon fill={hovered ? '#161616' : COLORS.GRAY.GRAY_600} />
        </motion.div>
      </Flex>
    </Stack>
  );
}
