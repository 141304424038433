import { Flex, Link, Stack } from '@chakra-ui/react';
import { PoweredByFinch } from '../PoweredByFinch';
import { legalLinks } from '../../shared/links';
import { COLORS } from '../../constant';

export const PublicPayStatementMappingFooter = () => {
  return (
    <Stack width={'100%'} gap={'25px'} paddingTop={'56px'}>
      <Flex width={'100%'} height={'1px'} background={'#D9D9D9'}></Flex>
      <Flex
        width={'100%'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexDirection={'row'}
        flexGrow={1}
      >
        <PoweredByFinch />
        <Flex alignItems={'center'} direction={'row'} gap={4}>
          <Link
            color={COLORS.GRAY.GRAY_600}
            fontSize={'14px'}
            href={legalLinks.tos}
            target="_blank"
          >
            Terms of Service
          </Link>
          <Link
            color={COLORS.GRAY.GRAY_600}
            fontSize={'14px'}
            href={legalLinks.privacy}
            target="_blank"
          >
            Privacy Policy
          </Link>
          <Link
            color={COLORS.GRAY.GRAY_600}
            fontSize={'14px'}
            href={legalLinks.security}
            target="_blank"
          >
            Security
          </Link>
        </Flex>
      </Flex>
    </Stack>
  );
};
