import React from 'react';

export const SendLinkSVG = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="32" fill="#EFF1FF" />
      <path
        d="M20.834 44.0834V19.9167L49.5198 32.0001L20.834 44.0834ZM22.9282 40.8805L44.099 32.0001L22.9282 23.0576V29.7759L32.0111 32.0001L22.9282 34.1797V40.8805Z"
        fill="#3E4AE7"
      />
    </svg>
  );
};
