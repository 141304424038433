import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SuccessCircle from '../../assets/images/icons/successCircle.svg';
import { UnlockPublicPaystatementMappingModal } from '../../connection-detail-v2/components/UnlockPublicPaystatementMappingModal';
import { PayStatementMapping } from '../../connection-detail-v2/pages/pay-statement-mapping/pages';
import {
  Item,
  Label,
} from '../../connection-detail-v2/pages/pay-statement-mapping/types';
import { PageHeading } from '../../shared/Typography';
import { baseURL } from '../../utils/baseUrls';
import { PublicPayStatementMappingFooter } from './PublicPayStatementMappingFooter';
import { COLORS } from '../../constant';
import { analyticsClient } from '../../utils/analytics/client';

const CheckmarkSVG = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      pointerEvents={'none'}
    >
      <path
        d="M8.9375 13L13.8958 8.0625L12.8333 7L8.9375 10.875L7.16667 9.125L6.10417 10.1875L8.9375 13ZM10 18C8.90278 18 7.86806 17.7917 6.89583 17.375C5.92361 16.9583 5.07292 16.3854 4.34375 15.6562C3.61458 14.9271 3.04167 14.0764 2.625 13.1042C2.20833 12.1319 2 11.0972 2 10C2 8.88889 2.20833 7.85069 2.625 6.88542C3.04167 5.92014 3.61458 5.07292 4.34375 4.34375C5.07292 3.61458 5.92361 3.04167 6.89583 2.625C7.86806 2.20833 8.90278 2 10 2C11.1111 2 12.1493 2.20833 13.1146 2.625C14.0799 3.04167 14.9271 3.61458 15.6562 4.34375C16.3854 5.07292 16.9583 5.92014 17.375 6.88542C17.7917 7.85069 18 8.88889 18 10C18 11.0972 17.7917 12.1319 17.375 13.1042C16.9583 14.0764 16.3854 14.9271 15.6562 15.6562C14.9271 16.3854 14.0799 16.9583 13.1146 17.375C12.1493 17.7917 11.1111 18 10 18Z"
        fill="#027A48"
      />
    </svg>
  );
};

const SubmitButton = ({
  onClick,
  isLoading,
}: {
  onClick: () => void;
  isDisabled: boolean;
  isLoading: boolean;
}) => {
  return (
    <Flex justifyContent={'flex-end'}>
      <Button
        variant={'primary'}
        onClick={onClick}
        isLoading={isLoading}
        background={'#161616'}
      >
        <Flex gap={2} alignItems={'center'}>
          <Text>Submit</Text>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.4785 7.83325L5.81185 12.4999L6.99935 13.6666L13.666 6.99992L6.99935 0.333252L5.81185 1.49992L10.4785 6.16658H0.332684V7.83325H10.4785Z"
              fill="white"
            />
          </svg>
        </Flex>
      </Button>
    </Flex>
  );
};

export const PublicPayStatementMapping = () => {
  const { code } = useParams<{ code: string }>();
  const [payStatementItem, setPayStatementItem] = useState<Array<Item>>([]);
  const [payStatementItemLabels, setPayStatementItemLabels] = useState<
    Array<Label>
  >([]);

  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [pin, setPin] = useState('');
  const [correctPin, setCorrectPin] = useState<boolean | null>(null);
  const [clearPin, setClearPin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    const execute = async () => {
      if (code && correctPin) {
        setLoading(true);
        await Promise.all([
          fetch(
            `${baseURL}/api/v1/employer/${code}/pay-statement-item?pin=${pin}`,
          )
            .then((res) => res.json())
            .then((data) => setPayStatementItem(data)),
          fetch(
            `${baseURL}/api/v1/employer/${code}/pay-statement-item/labels?pin=${pin}`,
          )
            .then((res) => res.json())
            .then((data) => setPayStatementItemLabels(data)),
        ]);
        setLoading(false);
      }
    };

    execute();
  }, [code, correctPin]);

  return (
    <Box maxW={1600} mx={'auto'} py={10}>
      <Flex gap={8} flexDir={'column'}>
        {!modalIsOpen && !completed ? (
          <>
            <Card flexDir={'column'} gap={4} padding={'24px'}>
              <Flex direction={'row'} width={'100%'}>
                <Flex direction={'row'} width={'100%'} gap={10}>
                  <Stack width={'100%'}>
                    <PageHeading>Payroll Mapping</PageHeading>
                    <Text
                      color={COLORS.GRAY.GRAY_600}
                      fontSize="16px"
                      maxW={600}
                    >
                      In order to complete your integration setup, we need your
                      help to label specific Earnings, Deductions, and
                      Contributions found in your payroll system. Please follow
                      the instructions below.
                    </Text>
                  </Stack>
                  <Stack width={'100%'}>
                    <Flex direction={'row'} alignItems={'center'} gap={1}>
                      <CheckmarkSVG />
                      <Text as="span" fontWeight={600} color={'#161616'}>
                        Step 1
                      </Text>
                    </Flex>
                    <Text color={COLORS.GRAY.GRAY_600}>
                      All items found in your payroll system will appear under
                      the Payroll Items column. Please assign your payroll items
                      to the labels provided. Select all that apply.
                    </Text>
                    <Text color={COLORS.GRAY.GRAY_600} fontSize="16px">
                      Don’t worry if there are Payroll Items that do not match
                      the labels provided. This is dependent on plan design.
                    </Text>
                  </Stack>
                  <Stack width={'100%'}>
                    <Flex direction={'row'} alignItems={'center'} gap={1}>
                      <CheckmarkSVG />
                      <Text as="span" fontWeight={600} color={'#161616'}>
                        Step 2
                      </Text>
                    </Flex>
                    <Text color={COLORS.GRAY.GRAY_600}>
                      Once you’ve labeled your Payroll Items, indicate which
                      Earnings are plan eligible by toggling the Eligibility
                      switch “On” or “Off”.
                    </Text>
                  </Stack>
                  <Stack width={'100%'}>
                    <Flex direction={'row'} alignItems={'center'} gap={1}>
                      <CheckmarkSVG />
                      <Text as="span" fontWeight={600} color={'#161616'}>
                        Step 3
                      </Text>
                    </Flex>
                    <Text color={COLORS.GRAY.GRAY_600}>
                      Once every item has a label, please hit “Submit”.
                    </Text>
                  </Stack>
                </Flex>
              </Flex>
            </Card>
            <PayStatementMapping
              payStatementItem={payStatementItem}
              payStatementItemLabels={payStatementItemLabels}
              isPayStatementsLoading={loading}
              enableControls={false}
              defaultEditing={true}
              submitButton={({ onClick, ...props }) => (
                <SubmitButton
                  {...props}
                  onClick={async () => {
                    try {
                      await onClick();

                      setPayStatementItem([]);
                      setPayStatementItemLabels([]);
                      setModalIsOpen(true);
                      setPin('');
                      setCompleted(true);
                    } catch {
                      //
                    }
                  }}
                />
              )}
              code={code}
              pin={pin}
              footer={<PublicPayStatementMappingFooter />}
            />
          </>
        ) : null}
        <UnlockPublicPaystatementMappingModal
          isOpen={modalIsOpen && !completed}
          setPin={setPin}
          pin={pin}
          correctPin={correctPin}
          clearPin={clearPin}
          onChange={() => {
            setClearPin(true);
          }}
          onLock={async (pin) => {
            await fetch(
              `${baseURL}/api/v1/employer/${code}/pay-statement-item?pin=${pin}`,
              {
                method: 'GET',
              },
            ).then(async (e) => {
              if (e.status >= 400) {
                analyticsClient.track('pay_statement_pin_input_incorrect');

                setCorrectPin(false);
                setClearPin(false);
                setModalIsOpen(true);
              } else {
                analyticsClient.track('pay_statement_pin_input_correct');

                setCorrectPin(true);
                setModalIsOpen(false);
              }
            });
          }}
        />
        <Modal isOpen={completed} onClose={() => null} isCentered size="md">
          <ModalOverlay />
          <ModalContent maxWidth="400px" minHeight="700px" borderRadius="20px">
            <ModalBody padding="8px 20px" alignContent="center">
              <Stack>
                <Flex>
                  <Spacer />
                  <Image src={SuccessCircle} width="64px" height="64px" />
                  <Spacer />
                </Flex>
                <Spacer height="24px" />
                <Heading
                  fontWeight="semibold"
                  fontSize="20px"
                  lineHeight="26px"
                  textAlign="center"
                  marginBottom="8px"
                >
                  Thank you
                </Heading>
                <Text textAlign="center" marginBottom="96px">
                  You may close the tab now.
                </Text>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};
