import {
  Button,
  Flex,
  Input,
  ModalFooter,
  Stack,
  Text,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';

import { validate } from 'email-validator';
import { useState } from 'react';
import { CopyComponent } from './CopyComponent';
import { ModalTemplate } from './ModalTemplate';
import { SendViaEmail } from './SendViaEmail';

export const CreatePayStatementLinkModal = ({
  isOpen,
  onClose,
  onSave,
  linkUrl,
  onOpenEmailModal,
  isSubmitting,
}: {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  onSave: (email: string) => void;
  linkUrl: string;
  onOpenEmailModal: () => void;
  isSubmitting: boolean;
}) => {
  const [email, setEmail] = useState('');

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <ModalFooter width={'100%'}>
          <Stack direction={'column'} width={'100%'} gap={8}>
            <Flex direction={'column'} gap={4}>
              {false && (
                <Text
                  textAlign={'center'}
                  textDecoration={'underline'}
                  fontSize={'14px'}
                  fontWeight={'400'}
                  color={COLORS.FINCH.GRAY}
                  cursor={'pointer'}
                  onClick={() => {
                    onOpenEmailModal();
                  }}
                >
                  Send via email instead
                </Text>
              )}
            </Flex>
            <Button
              onClick={() => {
                onSave(email);
              }}
              _disabled={{
                background: 'gray.500',
                pointerEvents: 'none',
              }}
              isDisabled={!validate(email) || isSubmitting}
              isLoading={isSubmitting}
              variant="solid"
              background={COLORS.FINCH.BLACK}
              _hover={{
                background: COLORS.FINCH.BLACK,
              }}
              color={COLORS.WHITE}
            >
              Send Link
            </Button>
          </Stack>
        </ModalFooter>
      }
    >
      <Flex direction={'column'} gap={2} alignItems={'center'}>
        <SendViaEmail />
        <Text fontSize={'20px'} fontWeight={'600'}>
          Email Payroll Mapping
        </Text>
        <Text textAlign={'center'}>
          Email this URL to your customer to have them complete the mapping
        </Text>
        <Stack paddingY={4} width={'100%'}>
          <Input
            value={email}
            placeholder="Recipient Email"
            onChange={(e) => setEmail(e.target.value)}
            style={{
              fontSize: '14px',
              color: COLORS.FINCH.GRAY,
              paddingLeft: '10px',
              paddingRight: '30px',
              margin: '0px',
            }}
          />
          {false && (
            <CopyComponent value={linkUrl} disabled={linkUrl.length === 0} />
          )}
        </Stack>
      </Flex>
    </ModalTemplate>
  );
};
