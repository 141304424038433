import { ContentBox } from 'components/ContentBox';
import { EmptyState } from 'components/EmptyState';

const EmptyPayStatementMappingIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="32" fill="#EFF1FF" />
      <path
        d="M23.4166 47L15.3333 38.9167L23.4445 30.8054L25.4028 32.75L20.6249 37.5279H46.9999V40.3054H20.6249L25.3749 45.0554L23.4166 47ZM40.5833 33.1946L38.6249 31.25L43.3749 26.5H16.9999V23.7221H43.3749L38.597 18.9446L40.5553 17L48.6666 25.1112L40.5833 33.1946Z"
        fill="#3E4AE7"
      />
    </svg>
  );
};

export const PayStatementMappingPanelWithSubText = ({
  subText,
}: {
  subText?: string;
}) => {
  return (
    <ContentBox py="64px">
      <EmptyState
        icon={<EmptyPayStatementMappingIcon />}
        text="There’s nothing here just yet."
        subText={
          subText ??
          'The employer needs to finish their authentication process before pay statements can be mapped.'
        }
      />
    </ContentBox>
  );
};

export const PayStatementMappingPanel = () => {
  return (
    <ContentBox py="64px">
      <EmptyState
        icon={<EmptyPayStatementMappingIcon />}
        text="There’s nothing here just yet."
        subText={
          'The employer needs to finish their authentication process before pay statements can be mapped.'
        }
      />
    </ContentBox>
  );
};
