import { Box, Divider, Flex, HStack, Spacer, Text } from '@chakra-ui/react';

import { Switch } from '@chakra-ui/react';
import { RightArrowIcon } from '../../../../shared/icons/RightArrowIcon';
import { Category, Option } from '../types';
import {
  OptionSelect,
  PayStatementMappingMultiSelect,
} from './pay-statement-mapping-multi-select';

import { COLORS } from '../../../../constant';
import { CancelXIcon } from '../../../../shared/icons/CancelXIcon';
import { CheckIcon } from '../../../../shared/icons/CheckIcon';
import type { ActionMeta } from 'react-select';

export function PayStatementCategoryRowV2({
  name,
  values,
  options,
  editing,
  disabled,
  onChange,
  onToggle,
  checked,
  header,
  valueMap,
}: {
  editing: boolean;
  disabled: boolean;
  name: string;
  options: Option[];
  values?: string[];
  onChange: (value: string[], meta: ActionMeta<OptionSelect>) => void;
  onToggle?: (polarity: boolean) => void;
  checked?: boolean;
  header: Category;
  valueMap: Partial<
    Record<Category, Record<string, string | null | undefined>>
  >;
}) {
  const active = editing && !disabled;
  const isToggleDisabled =
    !active || !values?.filter((v) => valueMap?.[header]?.[v])?.length;

  return (
    <Box>
      <Divider />
      <HStack paddingX="24px" paddingY="12px" gap="32px">
        <Box width="100%" maxWidth="580px">
          <Flex width="100%" maxWidth="580px">
            <Text fontSize={'14px'}>{name}</Text>
            <Spacer />
            <Box>
              <RightArrowIcon />
            </Box>
          </Flex>
        </Box>
        <Box width="100%">
          <Flex width="100%">
            <PayStatementMappingMultiSelect
              allSelected={valueMap[header]}
              currentName={name}
              options={options.map((option) => ({
                label: option.value ?? '',
                value: option.value ?? '',
                disabledValue: valueMap[header]?.[option.value ?? ''] ?? '',
                isDisabled: valueMap[header]?.[option.value ?? ''] === null,
              }))}
              selected={
                (values?.map((value) => ({
                  label: value ?? '',
                  value: value ?? '',
                })) ?? []) as OptionSelect[]
              }
              onChange={(e, meta) => {
                onChange(
                  e.map((option) => option.value),
                  meta,
                );
              }}
              disabled={!active}
            />
          </Flex>
        </Box>
        {onToggle &&
        (checked !== undefined || editing) &&
        header === 'earnings' ? (
          <Box minWidth="73px">
            <HStack gap="12px">
              {editing ? (
                <Flex direction={'row'} gap={'12px'}>
                  <Switch
                    isChecked={checked}
                    defaultChecked={true}
                    onChange={(e) => onToggle(e.target.checked)}
                    isDisabled={isToggleDisabled}
                    disabled={isToggleDisabled}
                  />
                  <Text
                    color={checked ? COLORS.GREEN : COLORS.GRAY.GRAY_400}
                    fontSize="14px"
                    fontWeight={500}
                  >
                    {checked ? 'Yes' : 'No'}
                  </Text>
                </Flex>
              ) : (
                <Box
                  width={'fit-content'}
                  padding={'4px 8px'}
                  display={'flex'}
                  alignItems={'center'}
                  gap={'8px'}
                  borderRadius={'20px'}
                  background={checked ? '#ECFDF3' : '#F4F4F4'}
                >
                  <Box
                    width="8px"
                    height="8px"
                    borderRadius={'20px'}
                    background={checked ? '#027A48' : '#737373'}
                  ></Box>
                  <Text
                    fontSize={'14px'}
                    color={checked ? '#027A48' : '#737373'}
                  >
                    {checked ? 'Yes' : 'No'}
                  </Text>
                </Box>
              )}
            </HStack>
          </Box>
        ) : onToggle && header === 'earnings' ? (
          <Box minWidth="73px"></Box>
        ) : null}
      </HStack>
    </Box>
  );
}
